import "./CSS/game.css";
import "./CSS/loading.css";
import "./CSS/popup.css";
import * as THREE from "three";
import {OrbitControls} from "three/examples/jsm/controls/OrbitControls";
import {game} from "./script";
import { VRButton } from "three/addons/webxr/VRButton.js";
import {CSS3DObject} from "three/addons/renderers/CSS3DRenderer.js";
import { XRControllerModelFactory } from "three/addons/webxr/XRControllerModelFactory.js";
import {initScene,pieces,blackPieces,whitePieces,camera,renderer,scene,boardSquares,coordsMap,takenMap,takenWhite, initArray,takenBlack,loadQueen,manager} from "./scene.js";
function addPieceData(){
	for (let i = 0; i < pieces.length; i++){
		pieces[i].userData.pieceId = i;
		pieces[i].userData.taken = false;
		pieces[i].userData.hasMoved = false;
		if (pieces[i].userData.pieceId < 16) {
			pieces[i].userData.indexOfBoardPiece = i;
		}
		else{
			pieces[i].userData.indexOfBoardPiece = i+32;
		}
		pieces[i].userData.name = pieces[i].children[0].name;
		if (pieces[i].userData.name === "Pawn"){
			pieces[i].userData.moveTwo = true;
		}
		if (i < 16){
			pieces[i].userData.side = "white";
			whitePieces.push(pieces[i]);
		}
		else{
			pieces[i].userData.side = "black";
			blackPieces.push(pieces[i]);
		}
	}
}

function addPromotionData(){
	let i = modifiedData[0];
	let index = modifiedData[1];
	let side = modifiedData[2];
	pieces[i].userData.name = pieces[i].children[0].name;
	pieces[i].userData.pieceId = i;
	pieces[i].userData.indexOfBoardPiece = index;
	pieces[i].userData.taken = false;
	pieces[i].userData.isQueen = true;
	pieces[i].userData.side = side;
	pieces[i].userData.hasMoved = true;
	promotion = false;
}



const gameLogic = new game();
gameLogic.whitePieces = whitePieces;
gameLogic.blackPieces = blackPieces;
gameLogic.cells = boardSquares;
gameLogic.pieces = pieces;

const camControls = new OrbitControls(camera, renderer.domElement);

document.addEventListener("mousedown", onDocumentMouseDown, false);

let intersectsPiece = null;
let intersectsBoard = null;
function onDocumentMouseDown(event) {
	if (gameLogic.continue) {
		var vector = new THREE.Vector3(
			(event.clientX / window.innerWidth) * 2 - 1,
			-(event.clientY / window.innerHeight) * 2 + 1,
			0.5);
		var raycaster = new THREE.Raycaster();
		raycaster.setFromCamera(vector, camera);
		intersectsBoard = raycaster.intersectObjects(boardSquares);
		intersectsPiece = raycaster.intersectObjects(pieces, true);
		gameLogic.modified = [];
		gameLogic.givePiecesEventListeners(intersectsPiece, intersectsBoard);
	}
}



let modified = [];
let modifiedData = [];
let promotion = false;
function animate() {
	renderer.setAnimationLoop(render);
}

let onStart = false;

manager.onStart = function (url, itemsLoaded, itemsTotal) {
	document.getElementById("title").innerHTML = "Loading";
};

manager.onProgress = function (url, itemsLoaded, itemsTotal) {
	let percentage = Math.floor((itemsLoaded / itemsTotal) * 100).toString();
	if (!onStart) {
		document.getElementById("title").innerHTML = percentage;
		document.getElementById("pawn-fill").style.clipPath = `polygon(0% ${100 - percentage}%, 100% ${100 - percentage}%, 100% 100%, 0% 100%)`;
	}
};

manager.onLoad = function () {
	if (!onStart) {
		document.getElementById("pawn-container").classList.add("loading-finished");
		setTimeout(function() {
			document.getElementById("loading-screen").remove();
		}, 2000);
		document.getElementById("title").innerHTML = "Online Chess Game";
		onStart = true;
		initScene();
		addPieceData();
		gameLogic.initKing();
		camera.position.x = 8;
		camera.position.y = 18.3;
		camera.position.z = -0.45;
		camera.rotation.x = -1.59;
		camera.rotation.y = 0.41;
		camera.rotation.z = 1.63;
		init();
		animate();
	}
	if (promotion){
		addPromotionData();
	}
};


const raycaster = new THREE.Raycaster();
raycaster.setFromCamera(new THREE.Vector2(0, 0), camera);
const tempMatrix = new THREE.Matrix4();
let controller1, controller2;
let controllerGrip1, controllerGrip2;
const intersected = [];


function init() {
	setUpVRButton();
	enableVR();
	createControllers();
	createLine();
	createDolly();
}

function setUpVRButton() {
	const button = VRButton.createButton(renderer);
	document.body.appendChild(button);
}

function enableVR() {
	renderer.xr.enabled = true;
	renderer.xr.setReferenceSpaceType("local-floor");
}

function createControllers(){
	controller1 = renderer.xr.getController( 0 );
	controller1.addEventListener( "selectstart", onSelectStart );
	controller1.addEventListener( "selectend", onSelectEnd );

	scene.add( controller1 );

	controller2 = renderer.xr.getController( 1 );
	controller2.addEventListener( "selectstart", onSelectStart );
	controller2.addEventListener( "selectend", onSelectEnd );

	scene.add( controller2 );

	const controllerModelFactory = new XRControllerModelFactory();

	controllerGrip1 = renderer.xr.getControllerGrip( 0 );
	controllerGrip1.add( controllerModelFactory.createControllerModel( controllerGrip1 ) );
	scene.add( controllerGrip1 );

	controllerGrip2 = renderer.xr.getControllerGrip( 1 );
	controllerGrip2.add( controllerModelFactory.createControllerModel( controllerGrip2 ) );
	scene.add( controllerGrip2 );

}

function createLine(){
	const geometry = new THREE.BufferGeometry().setFromPoints( [ new THREE.Vector3( 0, 0, 0 ), new THREE.Vector3( 0, 0, - 1 ) ] );

	const line = new THREE.Line( geometry );
	line.name = "line";
	line.scale.z = 5;

	controller1.add( line.clone() );
	controller2.add( line.clone() );
}




function render() {
	//camControls.enabled = gameLogic.selected === null
	modified = gameLogic.modified;
	sessionStorage.setItem("movesLog", JSON.stringify(gameLogic.movesLog));
	if (modified.length > 0){
		if (modified[3] !== null){
			if (modified[3] < 16) {
				takenWhite.push(pieces[modified[3]]);
				pieces[modified[3]].position.x = -takenMap[initArray[takenWhite.indexOf(pieces[modified[3]])].y];
				pieces[modified[3]].position.z = coordsMap[initArray[takenWhite.indexOf(pieces[modified[3]])].x];
			}
			else if (modified[3] >= 16){
				takenBlack.push(pieces[modified[3]]);
				pieces[modified[3]].position.x = takenMap[initArray[takenBlack.indexOf(pieces[modified[3]])].y];
				pieces[modified[3]].position.z = -coordsMap[initArray[takenBlack.indexOf(pieces[modified[3]])].x];
			}
		}
		if (modified[4] !== null){
			console.log(modified);
			modifiedData[0] = modified[0];
			modifiedData[1] = pieces[modified[0]].userData.indexOfBoardPiece;
			modifiedData[2] = pieces[modified[0]].userData.side;
			promotion = true;
			loadQueen(modified[0], modified[4], modified[2], modified[1]);
		}
		if (modified[6] !== null){
			let castle = gameLogic.castle;
			pieces[castle[0]].position.x = coordsMap[castle[2]];
			pieces[castle[0]].position.z = coordsMap[castle[1]];
		}
		pieces[modified[0]].position.x = coordsMap[modified[2]];
		pieces[modified[0]].position.z = coordsMap[modified[1]];
		modified = [];
		gameLogic.modified = [];
		gameLogic.castle = [];
		updateTurnOverlay();
	}
	if (renderer.xr.isPresenting) {
		gameLogic.vr = true;
		cleanIntersected();

		intersectObjects( controller1 );
		intersectObjects( controller2 );
		dollyMove();
	}

	renderer.render(scene, camera);
}


function onSelectStart( event ) {
	const controller = event.target;

	const intersections = getIntersections( controller );

	if ( intersections.length > 0 ) {

		const intersection = intersections[ 0 ];

		const object = intersection.object;

		controller.userData.selected = object;

	}
	gameLogic.modified = [];
	gameLogic.givePiecesEventListeners(intersectsPiece, intersectsBoard);

}

function onSelectEnd( event ) {

	const controller = event.target;

	if ( controller.userData.selected !== undefined ) {

		controller.userData.selected = undefined;

	}


}

function getIntersections( controller ) {

	tempMatrix.identity().extractRotation( controller.matrixWorld );

	raycaster.ray.origin.setFromMatrixPosition( controller.matrixWorld );
	raycaster.ray.direction.set( 0, 0, - 1 ).applyMatrix4( tempMatrix );

	intersectsBoard = raycaster.intersectObjects(boardSquares);
	intersectsPiece = raycaster.intersectObjects(pieces, true);

	return raycaster.intersectObjects(gameLogic.turn ? whitePieces : blackPieces, true);

}

function intersectObjects( controller ) {

	// Do not highlight when already selected

	if ( controller.userData.selected !== undefined ) return;

	const line = controller.getObjectByName( "line" );
	const intersections = getIntersections( controller );

	if ( intersections.length > 0 ) {

		const intersection = intersections[ 0 ];

		const object = intersection.object;
		object.material.emissive.g = 1;
		intersected.push( object );

		line.scale.z = intersection.distance;

	} else {

		line.scale.z = 5;

	}

}

function cleanIntersected() {

	while ( intersected.length ) {

		const object = intersected.pop();
		object.material.emissive.g = 0;

	}
}

//https://codepen.io/jason-buchheim/pen/zYqYGXM?editors=0010 (Buchheim, 2023) - modified and refactored
var dolly;
var cameraVector = new THREE.Vector3(); // create once and reuse it!
// a variable to store the values from the last polling of the gamepads
const prevGamePads = new Map();

//default values for speed movement of each axis
var speedFactor = [1, 1, 1, 1];

function createDolly(){
	//dolly for camera
	dolly = new THREE.Group();
	dolly.position.set(0, 0, 0);
	dolly.name = "dolly";
	scene.add(dolly);
	dolly.add(camera);
	//add the controls to the dolly also or they will not move with the dolly
	dolly.add(controller1);
	dolly.add(controller2);
	dolly.add(controllerGrip1);
	dolly.add(controllerGrip2);
}

function dollyMove() {
	const session = renderer.xr.getSession();

	if (session) {
		const xrCamera = renderer.xr.getCamera(camera);
		xrCamera.getWorldDirection(cameraVector);

		if (isIterable(session.inputSources)) {
			let i = 0;
			for (const source of session.inputSources) {
				if (source && source.handedness) {
					const handedness = source.handedness;
					handleGamepad(source, handedness, i);
					i++;
				}
			}
		}
	}
}

function handleGamepad(source, handedness, index) {
	if (!source.gamepad) return;

	const controller = renderer.xr.getController(index);
	const old = prevGamePads.get(source);
	const data = {
		handedness: handedness,
		buttons: source.gamepad.buttons.map((b) => b.value),
		axes: source.gamepad.axes.slice(0),
	};

	if (old) {
		handleButtons(data, old);
		handleAxes(data, old, source);
	}

	prevGamePads.set(source, data);
}

function handleButtons(data, old) {
	data.buttons.forEach((value, i) => {
		if (value !== old.buttons[i] || Math.abs(value) > 0.8) {
			handleButtonActions(data, value, i);
		}
	});
}

function handleButtonActions(data, value, i) {
	const isButtonDown = value === 1;
	const rotationValue = isButtonDown ? 1 : Math.abs(value);

	if (i === 1) {
		const rotationDirection = data.handedness === "left" ? -1 : 1;
		dolly.rotateY(THREE.MathUtils.degToRad(rotationDirection * rotationValue));
	}

	if (data.handedness === "left" && isButtonDown && i === 3) {
		dolly.position.set(0, 5, 0);
	}
}

function handleAxes(data, old, source) {
	data.axes.forEach((value, i) => {
		if (Math.abs(value) > 0.2) {
			speedFactor[i] > 1 ? (speedFactor[i] = 1) : (speedFactor[i] *= 1.001);

			if (i === 2) {
				handleAxis2(data, source);
			}

			if (i === 3) {
				handleAxis3(data, source);
			}
		} else if (Math.abs(value) > 0.025) {
			speedFactor[i] = 0.025;
		}
	});
}

function handleAxis2(data, source) {
	if (data.handedness === "left") {
		dolly.position.x -= cameraVector.z * speedFactor[2] * data.axes[2];
		dolly.position.z += cameraVector.x * speedFactor[2] * data.axes[2];
	} else {
		dolly.rotateY(-THREE.MathUtils.degToRad(data.axes[2]));
	}
	camControls.update();
}

function handleAxis3(data, source) {
	if (data.handedness === "left") {
		dolly.position.y -= speedFactor[3] * data.axes[3];
	} else {
		dolly.position.x -= cameraVector.x * speedFactor[3] * data.axes[3];
		dolly.position.z -= cameraVector.z * speedFactor[3] * data.axes[3];
	}
	camControls.update();
}

function isIterable(obj) {
	if (obj == null) {
		return false;
	}
	return typeof obj[Symbol.iterator] === "function";
}
//end Reference

const turnOverlay = document.getElementById("turn-overlay");
const turnText = document.getElementById("turn-text");
const popup = document.getElementById("end-screen");
const box = document.getElementById("success-box");

function updateTurnOverlay() {
	if (gameLogic.turn) {
		turnText.textContent = gameLogic.turn ? "White's Turn" : "Your Turn";
		turnText.style.color = gameLogic.turn ? "White" : "Grey";
	}
	else {
		turnText.textContent = gameLogic.turn ? "Your Turn" : "Black's Turn";
		turnText.style.color = gameLogic.turn ? "White" : "Grey";
	}
}

updateTurnOverlay();
turnOverlay.hidden = false;
popup.style.pointerEvents = "none";
box.hidden = true;
// Add the turn overlay to the scene
const turnOverlayObject = new CSS3DObject(turnOverlay);
scene.add(turnOverlayObject);



