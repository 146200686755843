//https://levelup.gitconnected.com/creating-a-board-game-checkers-with-javascript-ecd562f985c2 (Branco, 2020)
//used as inspiration and a very early template. Most legacy code no longer exists in the current version of the game.


import {camera, objArray, updateScene} from "./scene.js";
import {endGame} from "./issue.js";
import * as THREE from "three";

updateScene();

class game { //game class
	/*----------- Game State Data ----------*/
	board = [ //board array
		0, 1, 2, 3, 4, 5, 6, 7,
		8, 9, 10, 11, 12, 13, 14, 15,
		null, null, null, null, null, null, null, null,
		null, null, null, null, null, null, null, null,
		null, null, null, null, null, null, null, null,
		null, null, null, null, null, null, null, null,
		16, 17, 18, 19, 20, 21, 22, 23,
		24, 25, 26, 27, 28, 29, 30, 31
	];

	/*------- Global Variables -------*/
	cells = [];
	whitePieces = [];
	blackPieces = [];
	piecesIndex = [[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15], [16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31]];
	saviourPieces = [[], []];
	pieces = [];
	modified = [];
	castle = [];
	oldColor = {};
	oldPiece = null;
	highlightedCells = [];
	checkText = document.getElementById("check-text");
	checkContainer = document.getElementById("end-screen");
	checkPopup = document.getElementById("success-box");
	popupAlert = document.getElementById("alert-text");
	popupPawn = document.getElementById("pawn");
	checkPositionsPawn = [[], []];
	checkPositionsRook = [[], []];
	checkPositionsBishop = [[], []];
	checkPositionsQueen = [[], []];
	checkPositionsKnight = [[], []];
	currentCheckPositions = [[], []];
	threatPositions = [];
	threatIndex = [-1, -1]; //0 is the piece that is threatening the black king therefore white piece
	check = [];
	incr = 0;
	movesLog = [];
	moveSend = [];
	/*--- Player Properties ---*/
	turn = true; //1 == white, 0 == black
	whiteScore = 16;
	blackScore = 16;
	playerPieces;
	continue = true;
	clientID = null;
	vr = false;
	promoted = "";
	promotedPiece = null;
	enpassantAvailable = [false,false];
	enpassantCol = 0;
	enpassantRow = 0;
	enpassantIndex = 0;
	enpassantMove = 0;
	enpassantId = 0;


	/*--- selected piece properties ---*/
	selectedPiece = {
		pieceId: -1,
		indexOfBoardPiece: -1,
		row: 0,
		col: 0,
		moveTwo: false,
		moves: [],
		hasMoved: false
	};
	selected = null;
	intersectsBoard = null;
	/*---------- Event Listeners ----------*/

	// initialize event listeners on pieces
	// This function handles the interactions with pieces and the board.
	givePiecesEventListeners(intersectsPiece, intersectsBoard) {
		// Determine which player's turn it is.
		let turnW = this.turn ? 1 : 0;
		this.oldPiece = null;
		this.intersectsBoard = intersectsBoard;
		// Check if the piece can be selected.
		const isValidPiece = intersectsPiece.length > 0 && intersectsPiece[0].object.parent.userData.taken !== true && (this.turn ? intersectsPiece[0].object.parent.userData.pieceId < 16 : intersectsPiece[0].object.parent.userData.pieceId >= 16);
		// If the piece is valid, update the appearance of the selected and previously selected pieces.
		if (isValidPiece) {
			if (this.selected && intersectsPiece[0] !== this.selected) {
				resetSelectedAppearance(this.selected, this.highlightedCells, this.oldColor);
			}

			// Determine if the current piece can be selected based on the game state. player not in check
			const canSelectPiece = !this.check[turnW] || this.saviourPieces[turnW].includes(intersectsPiece[0].object.parent) || intersectsPiece[0].object.userData.name === "King";
			// If the piece can be selected, update its appearance and retrieve the current player's pieces.
			if (canSelectPiece) {
				setSelectedAppearance(intersectsPiece[0], this);
				this.getPlayerPieces();
			}
		} else if (this.selected && (intersectsBoard < 1 || !this.highlightedCells.includes(intersectsBoard[0].object))) {
			// Reset the appearance of the selected piece and the highlighted cells.
			this.resetSelectedPieceProperties(this.selected, this.highlightedCells, this.oldColor);
		} else if (intersectsBoard[0] && this.selected && this.highlightedCells.includes(intersectsBoard[0].object)) {
			// Make a move to the selected board cell.
			this.makeMove(this.intersectsBoard[0].object.userData.index - this.selectedPiece.indexOfBoardPiece);
		}

		// Reset the appearance of the selected piece and the highlighted cells.
		function resetSelectedAppearance(selected, highlightedCells, oldColor) {
			selected.object.material.opacity = 1;
			selected.object.material.color = oldColor;
			for (let i = 0; i < highlightedCells.length; i++) {
				highlightedCells[i].material.opacity = 0;
				highlightedCells[i].material.color = {r: 0, g: 1, b: 0};
			}
		}

		// Set the appearance of the selected piece.
		function setSelectedAppearance(piece, context) {
			context.selected = piece;
			context.oldColor = context.selected.object.material.color;
			piece.object.material.transparent = true;
			piece.object.material.opacity = 0.7;
		}
	}

	/*---------- Logic ----------*/

	getPlayerPieces() { //gets the current player's pieces
		if (this.turn) {
			this.playerPieces = this.whitePieces;
		} else {
			this.playerPieces = this.blackPieces;
		}
		this.getSelectedPiece();
	}


	// resets selected piece properties
	resetSelectedPieceProperties() { //resets the selected piece properties
		if (this.selectedPiece.pieceId >= 0) {
			this.pieces[this.selectedPiece.pieceId].children[0].material.opacity = 1;
			this.pieces[this.selectedPiece.pieceId].children[0].material.color = this.oldColor;
		}
		for (let i = 0; i < this.highlightedCells.length; i++) {
			this.highlightedCells[i].material.opacity = 0;
			this.highlightedCells[i].material.color = {r: 0, g: 1, b: 0};
		}
		this.selected = null;
		this.highlightedCells = [];
		this.selectedPiece.pieceId = -1;
		this.selectedPiece.indexOfBoardPiece = -1;
		this.selectedPiece.row = 0;
		this.selectedPiece.col = 0;
		this.selectedPiece.moveTwo = false;
		this.selectedPiece.type = "";
		this.selectedPiece.moves = [];
		this.selectedPiece.hasMoved = false;
	}

	// gets the selected piece properties
	getSelectedPiece() {
		this.selectedPiece.pieceId = this.selected.object.parent.userData.pieceId;
		this.selectedPiece.indexOfBoardPiece = this.selected.object.parent.userData.indexOfBoardPiece;
		this.selectedPiece.row = Math.floor(this.selectedPiece.indexOfBoardPiece / 8);
		this.selectedPiece.col = Math.floor(this.selectedPiece.indexOfBoardPiece % 8);
		this.selectedPiece.type = this.selected.object.name;
		this.selectedPiece.moveTwo = this.selected.object.parent.userData.moveTwo;
		this.selectedPiece.hasMoved = this.selected.object.parent.userData.hasMoved;
		this.calculateMoves();
	}

	calculateMoves() { //switch statement to calculate moves for each piece
		switch (this.selectedPiece.type) {
		case "Rook":
			this.givePieceBorder(this.rook(this.turn, this.selectedPiece.indexOfBoardPiece, this.board));
			break;
		case "Knight":
			this.givePieceBorder(this.knight(this.turn, this.selectedPiece.indexOfBoardPiece, this.board));
			break;
		case "Bishop":
			this.givePieceBorder(this.bishop(this.turn, this.selectedPiece.indexOfBoardPiece, this.board));
			break;
		case "Queen":
			this.givePieceBorder(this.queen(this.turn, this.selectedPiece.indexOfBoardPiece, this.board));
			break;
		case "King":
			this.givePieceBorder(this.king(this.turn, this.selectedPiece.indexOfBoardPiece, this.board, true));
			break;
		default:
			this.givePieceBorder(this.pawn(this.turn, this.selectedPiece.indexOfBoardPiece, this.selectedPiece.moveTwo, this.board, true));
		}
	}

	pawn(turn, index, moveTwo, board, modifier) { //calculates moves for pawn
		let col = Math.floor(index % 8);
		let moves = [];
		if (turn) { //white
			if (board[index + 8] === null) {
				moves.push(8);
			}
			if (moveTwo && board[index + 8] === null) {
				if (board[index + 16] === null) {
					moves.push(16);
				}
			}
			if (board[index + 7] >= 16 && col !== 0) {
				moves.push(7);
			}
			if (board[index + 9] >= 16 && col !== 7) {
				moves.push(9);
			}
		} else { //black
			if (board[index - 8] === null) {
				moves.push(-8);
			}
			if (moveTwo && board[index - 8] === null) {
				if (board[index - 16] === null) {
					moves.push(-16);
				}
			}
			if (board[index - 7] < 16 && col !== 7 && board[index - 7] !== null) {
				moves.push(-7);
			}
			if (board[index - 9] < 16 && col !== 0 && board[index - 9] !== null) {
				moves.push(-9);
			}
		}
		if (modifier === true) { //checks for enpassant
			if (this.enpassantAvailable[this.turn ? 1 : 0] && (this.selectedPiece.col + 1 === this.enpassantCol || this.selectedPiece.col - 1 === this.enpassantCol)) {
				if (this.selectedPiece.row === this.enpassantRow) {
					moves.push(this.enpassantIndex - index);
					this.enpassantMove = this.enpassantIndex - index;
				}
			}
		}
		return moves;
	}

	rook(turn, index, board) { //calculates moves for rook
		let rowMove = this.getMovesInDirection(1, turn, board, index).concat(this.getMovesInDirection(-1, turn, board, index));
		let colMove = this.getMovesInDirection(8, turn, board, index).concat(this.getMovesInDirection(-8, turn, board, index));
		return rowMove.concat(colMove); //returns all possible moves
	}

	getMovesInDirection(direction, turn, board, index) { //calculates moves in a direction
		let row = Math.floor(index / 8);
		let col = Math.floor(index % 8);
		let moves = [];
		let maxOffset = 7 * (turn ? 8 : 1);
		for (let i = 1; i <= maxOffset; i++) {
			let offset = i * direction;
			let piece = board[index + offset];
			if (piece !== null) {
				if (turn && piece >= 16 || !turn && piece < 16) {
					if (Math.floor((index + offset) % 8) === col || Math.floor((index + offset) / 8) === row) {
						moves.push(offset);
					}
				}
				break; // exit loop if the piece is the player's own piece or an opponent's piece
			} else if (Math.floor((index + offset) % 8) === col || Math.floor((index + offset) / 8) === row) {
				moves.push(offset);
			}
		}
		return moves;
	}


	knight(turn, index, board) { //calculates moves for knight
		let col = Math.floor(index % 8);
		let moves = [-17, -15, -10, -6, 6, 10, 15, 17];
		let validMoves = [];

		for (let i = 0; i < moves.length; i++) {
			let destinationIndex = index + moves[i];
			if (destinationIndex < 0 || destinationIndex > 63) {
				continue; // skip invalid moves
			}
			let destinationPiece = board[destinationIndex];
			if (moves[i] === -1 && Math.floor(destinationIndex % 8) === 7) {
				continue; // skip left edge moves
			}
			if (moves[i] === 1 && Math.floor(destinationIndex % 8) === 0) {
				continue; // skip right edge moves
			}
			if (Math.floor(destinationIndex % 8) > col + 2 || Math.floor(destinationIndex % 8) < col - 2) {
				continue;
			}
			if ((turn && destinationPiece < 16 && destinationPiece !== null) || (!turn && destinationPiece >= 16)) {
			}
			else {
				validMoves.push(moves[i]);
			}
		}
		moves = validMoves;
		return moves; //returns all possible moves
	}

	bishop(turn, index, board) { //calculates moves for bishop
		let possibleMoves = [];
		let moves = [];

		// compute row and column of bishop
		let row = Math.floor(index / 8);
		let col = index % 8;

		// iterate over each diagonal direction
		for (let dx of [-1, 1]) {
			for (let dy of [-1, 1]) {
				// iterate over diagonal steps
				for (let i = 1; i < 8; i++) {
					// compute row and column of current position
					let r = row + i * dy;
					let c = col + i * dx;
					// check if current position is on board and on diagonal
					if (r >= 0 && r < 8 && c >= 0 && c < 8 && Math.abs(r - row) === Math.abs(c - col)) {
						// add diagonal step to possible moves
						possibleMoves.push(i * (8 * dy + dx));
						// if there is a piece at current position, stop iterating along this diagonal
						if (board[r * 8 + c] !== null) {
							const isWhite = board[index + (i * (8 * dy + dx))] < 16;
							if ((turn && isWhite) || (!turn && !isWhite)) {
								possibleMoves.pop();
							}
							break;
						}
					} else {
						// if current position is not on board or not on diagonal, stop iterating along this diagonal
						break;
					}
				}
			}
		}

		for (let i = 0; i < possibleMoves.length; i++) { //removes invalid moves
			if (possibleMoves[i] > 0 || possibleMoves[i] < 0) {
				moves.push(possibleMoves[i]);
			}
		}
		return moves;
	}

	queen(turn, index, board) { //calculates moves for queen
		let moves1 = this.rook(turn, index, board);
		let moves2 = this.bishop(turn, index, board);
		let moves = moves1.concat(moves2);
		moves.sort(function (a, b) { //sorts moves
			return a - b;
		});
		return moves;
	}

	king(turn, index, board, modifier) { //calculates moves for king
		let col = Math.floor(index % 8);
		let moves = [-9, -8, -7, -1, 1, 7, 8, 9];
		let validMoves = [];
		for (let i = 0; i < moves.length; i++) { //calculates valid moves
			let destinationIndex = index + moves[i];
			if (destinationIndex < 0 || destinationIndex > 63) {
				continue; // skip invalid moves
			}
			let destinationPiece = board[destinationIndex];
			if (moves[i] === -1 && Math.floor(destinationIndex % 8) === 7) {
				continue; // skip left edge moves
			}
			if (moves[i] === 1 && Math.floor(destinationIndex % 8) === 0) {
				continue; // skip right edge moves
			}
			if (Math.floor(destinationIndex % 8) > col + 1 || Math.floor(destinationIndex % 8) < col - 1) {
				continue;
			}
			if ((turn && destinationPiece < 16 && destinationPiece !== null) || (!turn && destinationPiece >= 16)) {
			}
			else {
				validMoves.push(moves[i]);
			}
		}
		if (modifier) {
			return this.kingPinning(validMoves, index, turn, board);
		}
		return validMoves;
	}

	kingPinning(validMoves, index, turn, board) { //calculates moves for king when pinned
		let turnW = turn ? 1 : 0;
		let kingIndex = turn ? 3 : 27;
		let localBishop = [];
		let localRook = [];
		let localPawn = [];
		let localKnight = [];
		let localQueen = [];
		let possibleMoves;
		let invalidMoves = [];
		for (let i = 0; i < validMoves.length; i++) {
			let newBoard = board.slice();
			newBoard[index] = null;
			newBoard[index + validMoves[i]] = kingIndex;
			let newKingPos = index + validMoves[i];

			// check if new king position is under threat
			let kingUnderThreat = false;
			for (let j = 0; j < this.piecesIndex[turnW].length; j++) {
				let pieceId = this.piecesIndex[turnW][j];
				let pieceType = this.pieces[pieceId].userData.name;
				let pieceIndex = this.pieces[pieceId].userData.indexOfBoardPiece;
				let newMoves;
				switch (pieceType) {
				case "Rook":
					newMoves = this.rook(!turn, pieceIndex, newBoard);
					break;
				case "Knight":
					newMoves = this.knight(!turn, pieceIndex, newBoard);
					break;
				case "Bishop":
					newMoves = this.bishop(!turn, pieceIndex, newBoard);
					break;
				case "Queen":
					newMoves = this.queen(!turn, pieceIndex, newBoard);
					break;
				case "King":
					newMoves = this.king(!turn, pieceIndex, newBoard,false);
					break;
				default:
					newMoves = this.checkPawnAlt(turn, pieceIndex,newBoard);
					break;
				}
				let threatMoves = newMoves.map(v => v + newBoard.indexOf(pieceId));
				if (threatMoves.includes(newKingPos)) {
					kingUnderThreat = true;
					break;
				}
			}

			if (kingUnderThreat) {
				// add invalid move if king is under threat
				invalidMoves.push(validMoves[i]);
				continue; // skip remaining checks for this move
			}

			localPawn = this.checkPawn(turn, index + validMoves[i], newBoard).map(v => v + index + validMoves[i]);
			localBishop = this.bishop(turn, index + validMoves[i], newBoard).map(v => v + index + validMoves[i]);
			localRook = this.rook(turn, index + validMoves[i], newBoard).map(v => v + index + validMoves[i]);
			localKnight = this.knight(turn, index + validMoves[i], newBoard).map(v => v + index + validMoves[i]);
			localQueen = this.queen(turn, index + validMoves[i], newBoard).map(v => v + index + validMoves[i]);
			//for each validmove position, check each pieces moves from that position, giving you all possible check positions of the new space
			//using the new space see if a valid piece exists in any of the checkable positions
			//if a threatable piece exists do not allow movement into that position
			localPawn.forEach(pawnIndex => {
				if (newBoard[pawnIndex] && this.pieces[newBoard[pawnIndex]].userData.name === "Pawn" && this.piecesIndex[turnW].includes(newBoard[pawnIndex])) {
					invalidMoves.push(validMoves[i]);
				}
			});
			localBishop.forEach(bishopIndex => {
				if (newBoard[bishopIndex] && this.pieces[newBoard[bishopIndex]].userData.name === "Bishop" && this.piecesIndex[turnW].includes(newBoard[bishopIndex])) {
					invalidMoves.push(validMoves[i]);
				}
			});
			localRook.forEach(rookIndex => {
				if (newBoard[rookIndex] && this.pieces[newBoard[rookIndex]].userData.name === "Rook" && this.piecesIndex[turnW].includes(newBoard[rookIndex])) {
					invalidMoves.push(validMoves[i]);
				}
			});
			localKnight.forEach(knightIndex => {
				if (newBoard[knightIndex] && this.pieces[newBoard[knightIndex]].userData.name === "Knight" && this.piecesIndex[turnW].includes(newBoard[knightIndex])) {
					invalidMoves.push(validMoves[i]);
				}
			});
			localQueen.forEach(queenIndex => {
				if (newBoard[queenIndex] && this.pieces[newBoard[queenIndex]].userData.name === "Queen" && this.piecesIndex[turnW].includes(newBoard[queenIndex])) {
					invalidMoves.push(validMoves[i]);
				}
			});
		}
		possibleMoves = validMoves.filter(function (value) { //remove invalid moves from valid moves
			return !invalidMoves.includes(value);
		});
		return possibleMoves; //return valid moves

	}


	piecePinning(validMoves, index, turn, board) { //check if piece is pinned
		let turnW = turn ? 1 : 0;
		let kingIndex = turn ? 3 : 27;
		let localBishop = [];
		let localRook = [];
		let localPawn = [];
		let localKnight = [];
		let localQueen = [];
		let possibleMoves;
		let invalidMoves = [];
		let kingPos;
		for (let i = 0; i < validMoves.length; i++) {
			let newBoard = board.slice();
			newBoard[index] = null;
			kingPos = board.indexOf(kingIndex);
			newBoard[index + validMoves[i]] = this.selectedPiece.pieceId;

			localPawn = this.checkPawn(turn, kingPos, newBoard).map(v => v + kingPos);
			localBishop = this.bishop(turn, kingPos, newBoard).map(v => v + kingPos);
			localRook = this.rook(turn, kingPos, newBoard).map(v => v + kingPos);
			localKnight = this.knight(turn, kingPos, newBoard).map(v => v + kingPos);
			localQueen = this.queen(turn, kingPos, newBoard).map(v => v + kingPos);
			//for each validmove position, check each pieces moves from that position, giving you all possible check positions of the new space
			//using the new space see if a valid piece exists in any of the checkable positions
			//if a threatable piece exists do not allow movement into that position

			localPawn.forEach(pawnIndex => {
				if (newBoard[pawnIndex] && this.pieces[newBoard[pawnIndex]].userData.name === "Pawn" && this.piecesIndex[turnW].includes(newBoard[pawnIndex])) {
					invalidMoves.push(validMoves[i]);
				}
			});
			localBishop.forEach(bishopIndex => {
				if (newBoard[bishopIndex] && this.pieces[newBoard[bishopIndex]].userData.name === "Bishop" && this.piecesIndex[turnW].includes(newBoard[bishopIndex])) {
					invalidMoves.push(validMoves[i]);
				}
			});
			localRook.forEach(rookIndex => {
				if (newBoard[rookIndex] && this.pieces[newBoard[rookIndex]].userData.name === "Rook" && this.piecesIndex[turnW].includes(newBoard[rookIndex])) {
					invalidMoves.push(validMoves[i]);
				}
			});
			localKnight.forEach(knightIndex => {
				if (newBoard[knightIndex] && this.pieces[newBoard[knightIndex]].userData.name === "Knight" && this.piecesIndex[turnW].includes(newBoard[knightIndex])) {
					invalidMoves.push(validMoves[i]);
				}
			});
			localQueen.forEach(queenIndex => {
				if (newBoard[queenIndex] && this.pieces[newBoard[queenIndex]].userData.name === "Queen" && this.piecesIndex[turnW].includes(newBoard[queenIndex])) {
					invalidMoves.push(validMoves[i]);
				}
			});
		}

		possibleMoves = validMoves.filter(function (value) { //remove invalid moves from valid moves
			return !invalidMoves.includes(value);
		});
		return possibleMoves; //return valid moves

	}

	// gives the piece a green highlight for the user (showing its movable)
	givePieceBorder(moves) {
		let turnW = this.turn ? 1 : 0;
		let threatMoves = [];
		if (this.selectedPiece.type === "King" && this.selectedPiece.hasMoved === false && !this.check[turnW]){ //player not in check
			moves = moves.concat(this.castling());
		}
		for (let i = 0; i < this.threatPositions.length; i++) {
			threatMoves.push(this.threatPositions[i] - this.selectedPiece.indexOfBoardPiece);
		}
		if (this.check[turnW] && this.selectedPiece.type !== "King") { //player in check
			this.selectedPiece.moves = moves.filter(x => threatMoves.includes(x) || this.board[x + this.selectedPiece.indexOfBoardPiece] == this.threatIndex[turnW]); //index of opponent piece threatening king
		} else if (this.selectedPiece.type !== "King") {
			this.selectedPiece.moves = this.piecePinning(moves, this.selectedPiece.indexOfBoardPiece, this.turn, this.board);
		} else
			this.selectedPiece.moves = moves;
		if (this.selectedPiece.moves.length > 0) {
			this.pieces[this.selectedPiece.pieceId].children[0].material.color = {r: 0, g: 1, b: 0};
			this.giveCellsClick();
		}
	}

	// gives the cells on the board a 'click' based on the possible moves
	giveCellsClick() {
		for (let i = 0; i < this.selectedPiece.moves.length; i++) {
			this.cells[this.selectedPiece.indexOfBoardPiece + this.selectedPiece.moves[i]].material.opacity = 0.7;
			this.highlightedCells.push(this.cells[this.selectedPiece.indexOfBoardPiece + this.selectedPiece.moves[i]]);
		}
	}

	lastMoves = [];

	moveConvert(id, type, move, remove, castling, enpassant){ //converts move to chess notation
		let prefix = "";
		let suffix = "";
		switch(type) {
		case "King":
			prefix = "K";
			break;
		case "Queen":
			prefix = "Q";
			break;
		case "Rook":
			prefix = "R";
			break;
		case "Bishop":
			prefix = "B";
			break;
		case "Knight":
			prefix = "N";
			break;
		default:
			prefix = "";
			break;
		}
		if (remove) {
			prefix += "x";
		}
		if (castling !== null) {
			prefix = "O-O";
			if (castling) {
				prefix += "-O";
			}
		}
		if (enpassant) {
			suffix = " e.p.";
		}
		let piece = this.pieces[id];
		let newIndex = piece.userData.indexOfBoardPiece + move;
		let newRow = Math.floor(newIndex / 8);
		let newCol = Math.floor(newIndex % 8);
		newRow = 7 - newRow;
		newCol = 7 - newCol;
		let letter = String.fromCharCode(97 + newCol);
		let number = 8 - newRow;
		let moveString = "";
		if (castling === null) {
			moveString = prefix + letter + number + suffix;
		}
		else{
			moveString = prefix;
		}
		console.log(moveString); //debug
		let moveColor = this.turn ? "white" : "black";
		let moveElement = document.createElement("div");
		moveElement.textContent = moveString;
		moveElement.classList.add(moveColor);
		document.getElementById("last-moves").appendChild(moveElement); //add move to last moves
		this.lastMoves.push(moveString); //add move to last moves array
		if (this.lastMoves.length > 36) {
			let removedElement = document.querySelector(`#last-moves div.${moveColor}:nth-child(1)`); //remove first move if more than 36 moves
			if (removedElement) {
				removedElement.remove();
			}
		}
	}
	//make move
	makeMove(number) { //number is the move from the cell
		this.movesLog.push([this.selectedPiece.pieceId, number]);
		console.log(this.movesLog); //debug
		this.moveSend = [this.selectedPiece.pieceId, number]; //send move to server
		let castling = null;//false - kingside, true - queenside
		if (this.selectedPiece.type === "King" && number === -2){ //castling
			castling = false;
		}
		else if (this.selectedPiece.type === "King" && number === 2){
			castling = true;
		}
		let previousIndex = this.selectedPiece.indexOfBoardPiece;
		this.selectedPiece.indexOfBoardPiece += number;
		this.selectedPiece.row = Math.floor(this.selectedPiece.indexOfBoardPiece / 8); //update row and col
		this.selectedPiece.col = Math.floor(this.selectedPiece.indexOfBoardPiece % 8);
		if (this.board[this.selectedPiece.indexOfBoardPiece] !== null) { //remove piece if there is one
			this.moveConvert(this.selectedPiece.pieceId, this.selectedPiece.type, number, true, castling);
			this.changeData(previousIndex, this.selectedPiece.indexOfBoardPiece, true, castling);
		}
		else if (number === this.enpassantMove && this.selectedPiece.type === "Pawn"){ //enpassant
			let enpassant = this.enpassantIndex;
			this.moveConvert(this.selectedPiece.pieceId, this.selectedPiece.type, number, true, false, true);
			this.changeData(previousIndex, this.selectedPiece.indexOfBoardPiece, true, castling, enpassant);
		}
		else { //regular move
			this.moveConvert(this.selectedPiece.pieceId, this.selectedPiece.type, number,false, castling);
			this.changeData(previousIndex, this.selectedPiece.indexOfBoardPiece, false, castling);
		}
	}

	// Changes the board states data on the back end

	changeData(previousIndex, modifiedIndex, removePiece, castling , enpassant){ //previousIndex is the index of the piece before the move, modifiedIndex is the index of the piece after the move
		if (removePiece) { //remove piece if there is one
			if (enpassant !== undefined){
				this.oldPiece = this.board[this.board.indexOf(this.enpassantId)];
			}
			else {
				this.oldPiece = this.board[modifiedIndex];
			}
			let removeID = this.piecesIndex[this.turn ? 1 : 0].indexOf(this.oldPiece);
			this.piecesIndex[this.turn ? 1 : 0].splice(this.piecesIndex[this.turn ? 1 : 0].indexOf(this.oldPiece), 1);
			if (this.turn && this.selectedPiece.pieceId < 16) {
				this.blackScore--;
			}
			if (this.turn === false && this.selectedPiece.pieceId >= 16) {
				this.whiteScore--;
			}
			if (this.turn) {
				this.blackPieces.splice(removeID, 1);
			} else {
				this.whitePieces.splice(removeID, 1);
			}
			this.pieces[this.oldPiece].userData.taken = true;
		}
		if (enpassant !== undefined){
			this.board[this.board.indexOf(this.enpassantId)] = null;
		}
		this.board[previousIndex] = null;
		this.board[modifiedIndex] = this.selectedPiece.pieceId;
		if (castling === false){ //kingside castling
			this.castle = [this.turn ? 0 : 24, this.turn ? 0 : 7,  2];
			this.board[this.turn ? 0 : 56] = null;
			this.board[this.turn ? 2 : 58] = this.turn ? 0 : 24;
			this.pieces[this.turn ? 0 : 24].userData.indexOfBoardPiece = this.turn ? 2 : 58;
			this.pieces[this.turn ? 0 : 24].userData.hasMoved = true;
		}
		else if (castling === true){ //queenside castling
			this.castle = [this.turn ? 7 : 31, this.turn ? 0 : 7, 4];
			this.board[this.turn ? 7 : 63] = null;
			this.board[this.turn ? 4 : 60] = this.turn ? 7 : 31;
			this.pieces[this.turn ? 7 : 31].userData.indexOfBoardPiece = this.turn ? 4 : 60;
			this.pieces[this.turn ? 7 : 31].userData.hasMoved = true;

		}
		if (this.turn && this.selectedPiece.pieceId < 16 && modifiedIndex >= 56 && this.selectedPiece.type === "Pawn") { //promotion
			this.continue = false;
			this.promotion();
		}
		else if (!this.turn && this.selectedPiece.pieceId >= 16 && modifiedIndex <= 7 && this.selectedPiece.type === "Pawn") { //promotion
			this.continue = false;
			this.promotion();
		}
		else {
			if (this.turn && this.selectedPiece.pieceId < 16 && modifiedIndex >= 16) { //enpassant available
				if (this.selectedPiece.type === "Pawn" && this.selectedPiece.moveTwo) {
					this.enpassantAvailable[!this.turn ? 1 : 0] = true;
					this.enpassantCol = this.selectedPiece.col;
					this.enpassantRow = this.selectedPiece.row;
					this.enpassantIndex = this.selectedPiece.indexOfBoardPiece - 8;
					this.enpassantId = this.selectedPiece.pieceId;
				}
				this.selectedPiece.moveTwo = false;
			}
			if (!this.turn && this.selectedPiece.pieceId >= 16 && modifiedIndex <= 47) { //enpassant available
				if (this.selectedPiece.type === "Pawn" && this.selectedPiece.moveTwo) {
					this.enpassantAvailable[!this.turn ? 1 : 0] = true;
					this.enpassantCol = this.selectedPiece.col;
					this.enpassantRow = this.selectedPiece.row;
					this.enpassantIndex = this.selectedPiece.indexOfBoardPiece + 8;
					this.enpassantId = this.selectedPiece.pieceId;
				}
				this.selectedPiece.moveTwo = false;
			}
			this.updatePiece(); //update piece data
			this.modified = [this.selectedPiece.pieceId, this.selectedPiece.row, this.selectedPiece.col, this.oldPiece, null , this.turn, castling];
			this.continue = true;
			this.currentCheckPositions[this.turn ? 1 : 0] = this.checkablePositions(this.getKingIndex(this.turn), this.turn, 1, this.board); //check for check
			this.checkForWin(); //check for win
		}
	}


	promotion(){ //promotion
		if (this.clientID !== null && this.clientID != this.turn){
			if (this.promotedPiece === "Queen"){
				this.selectedPiece.type = "Queen";
				this.updatePiece();
				this.modified = [this.selectedPiece.pieceId, this.selectedPiece.row, this.selectedPiece.col, this.oldPiece, objArray[4], this.turn, null];
				this.continue = true;
				this.currentCheckPositions[this.turn ? 1 : 0] = this.checkablePositions(this.getKingIndex(this.turn), this.turn, 1, this.board);
				this.checkForWin();
			}
			else if (this.promotedPiece === "Rook") {
				this.selectedPiece.type = "Rook";
				this.updatePiece();
				this.modified = [this.selectedPiece.pieceId, this.selectedPiece.row, this.selectedPiece.col, this.oldPiece, objArray[0], this.turn, null];
				this.continue = true;
				this.currentCheckPositions[this.turn ? 1 : 0] = this.checkablePositions(this.getKingIndex(this.turn), this.turn, 1, this.board);
				this.checkForWin();
			}
			else if (this.promotedPiece === "Bishop") {
				this.selectedPiece.type = "Bishop";
				this.updatePiece();
				this.modified = [this.selectedPiece.pieceId, this.selectedPiece.row, this.selectedPiece.col, this.oldPiece, objArray[2], this.turn, null];
				this.continue = true;
				this.currentCheckPositions[this.turn ? 1 : 0] = this.checkablePositions(this.getKingIndex(this.turn), this.turn, 1, this.board);
				this.checkForWin();
			}
			else{
				this.selectedPiece.type = "Knight";
				this.updatePiece();
				this.modified = [this.selectedPiece.pieceId, this.selectedPiece.row, this.selectedPiece.col, this.oldPiece, objArray[1], this.turn, null];
				this.continue = true;
				this.currentCheckPositions[this.turn ? 1 : 0] = this.checkablePositions(this.getKingIndex(this.turn), this.turn, 1, this.board);
				this.checkForWin();
			}
		}
		else { //if client is the one who needs to promote
			const menu = document.getElementById("floating-menu");
			// Get the 2D screen position of the selected object
			const screenPosition = this.toScreenPosition(this.selected.object, camera);
			// Set the position of the menu and show it
			menu.style.left = `${screenPosition.x}px`;
			menu.style.top = `${screenPosition.y}px`;
			menu.style.display = "block";
			document.getElementById("option-1").addEventListener("click", () => {
				this.selectedPiece.type = "Queen";
				this.promoted = "Queen";
				this.updatePiece();
				this.modified = [this.selectedPiece.pieceId, this.selectedPiece.row, this.selectedPiece.col, this.oldPiece, objArray[4], this.turn, null];
				this.closeFloatingMenu();
				this.continue = true;
				this.currentCheckPositions[this.turn ? 1 : 0] = this.checkablePositions(this.getKingIndex(this.turn), this.turn, 1, this.board);
				this.checkForWin();
			});
			document.getElementById("option-2").addEventListener("click", () => {
				this.selectedPiece.type = "Rook";
				this.promoted = "Rook";
				this.updatePiece();
				this.modified = [this.selectedPiece.pieceId, this.selectedPiece.row, this.selectedPiece.col, this.oldPiece, objArray[0], this.turn, null];
				this.closeFloatingMenu();
				this.continue = true;
				this.currentCheckPositions[this.turn ? 1 : 0] = this.checkablePositions(this.getKingIndex(this.turn), this.turn, 1, this.board);
				this.checkForWin();
			});
			document.getElementById("option-3").addEventListener("click", () => {
				this.selectedPiece.type = "Bishop";
				this.promoted = "Bishop";
				this.updatePiece();
				this.modified = [this.selectedPiece.pieceId, this.selectedPiece.row, this.selectedPiece.col, this.oldPiece, objArray[2], this.turn, null];
				this.closeFloatingMenu();
				this.continue = true;
				this.currentCheckPositions[this.turn ? 1 : 0] = this.checkablePositions(this.getKingIndex(this.turn), this.turn, 1, this.board);
				this.checkForWin();
			});
			document.getElementById("option-4").addEventListener("click", () => {
				this.selectedPiece.type = "Knight";
				this.promoted = "Knight";
				this.updatePiece();
				this.modified = [this.selectedPiece.pieceId, this.selectedPiece.row, this.selectedPiece.col, this.oldPiece, objArray[1], this.turn, null];
				this.closeFloatingMenu();
				this.continue = true;
				this.currentCheckPositions[this.turn ? 1 : 0] = this.checkablePositions(this.getKingIndex(this.turn), this.turn, 1, this.board);
				this.checkForWin();
			});
		}
	}
	closeFloatingMenu() { //close the floating menu
		const menu = document.getElementById("floating-menu");
		menu.style.display = "none";
	}
	toScreenPosition(obj, camera) { //converts 3D position to 2D position
		const vector = new THREE.Vector3();
		const canvas = document.querySelector("canvas");

		obj.updateMatrixWorld();
		vector.setFromMatrixPosition(obj.matrixWorld);
		vector.project(camera);

		const widthHalf = canvas.clientWidth / 2;
		const heightHalf = canvas.clientHeight / 2;

		vector.x = (vector.x * widthHalf) + widthHalf;
		vector.y = -(vector.y * heightHalf) + heightHalf;

		return { x: vector.x, y: vector.y };
	}

	updatePiece() { //updates the piece's data
		this.pieces[this.selectedPiece.pieceId].userData.pieceId = this.selectedPiece.pieceId;
		this.pieces[this.selectedPiece.pieceId].userData.indexOfBoardPiece = this.selectedPiece.indexOfBoardPiece;
		this.pieces[this.selectedPiece.pieceId].userData.row = this.selectedPiece.row;
		this.pieces[this.selectedPiece.pieceId].userData.col = this.selectedPiece.col;
		this.pieces[this.selectedPiece.pieceId].userData.type = this.selectedPiece.type;
		this.pieces[this.selectedPiece.pieceId].userData.moveTwo = this.selectedPiece.moveTwo;
		this.pieces[this.selectedPiece.pieceId].userData.moves = this.selectedPiece.moves;
		if (this.pieces[this.selectedPiece.pieceId].userData.hasMoved === false){
			this.pieces[this.selectedPiece.pieceId].userData.hasMoved = true;
		}
	}

	// Checks for a win
	checkForWin() {
		let turnW = this.turn ? 1 : 0;
		let check = this.checkCheck(this.turn);
		if (check === 1) {
			if (this.turn) {
				console.log("White win");
				this.checkText.textContent = "White Win!!!";
				this.checkPopup.hidden = false;
				this.checkContainer.style.pointerEvents = "auto";
				endGame();
			} else if (!this.turn) {
				console.log("Black win");
				this.checkText.textContent = "Black Win!!!";
				this.checkText.style.color = "Black";
				this.checkText.style.opacity = "1";
				this.popupPawn.style.filter = "invert(10%)";
				this.checkPopup.hidden = false;
				this.checkContainer.style.pointerEvents = "auto";
				endGame();
			}
		}
		else if(check === 2){
			console.log("Stalemate");
			this.popupAlert.textContent = "Stalemate";
			this.checkText.textContent = "Game is a Draw";
			this.popupPawn.style.filter = "invert(30%) sepia(100%) saturate(500%) hue-rotate(190deg)";
			this.checkPopup.hidden = false;
			this.checkContainer.style.pointerEvents = "auto";
			endGame();
		}
		if (this.check[turnW] === true) {
			this.cells[this.board.indexOf(this.turn ? 3 : 27)].material.opacity = 0;
			this.cells[this.board.indexOf(this.turn ? 3 : 27)].material.color = {r: 0, g: 1, b: 0};
		}
		this.incr++;
		this.resetSelectedPieceProperties();
		this.changePlayer();
	}

	// Switches players turn
	changePlayer() {
		this.incr++;
		this.enpassantAvailable[this.turn ? 1 : 0] = false;
		if (this.turn) {
			this.turn = false;
			this.currentCheckPositions[this.turn ? 1 : 0] = this.checkablePositions(this.getKingIndex(this.turn), this.turn, 1, this.board);
		} else {
			this.turn = true;
			this.currentCheckPositions[this.turn ? 1 : 0] = this.checkablePositions(this.getKingIndex(this.turn), this.turn, 1, this.board);
		}
	}


	castling(){ //castling
		let moves = [];
		let path = [];
		let index = this.turn ? 3 : 59;
		let kingSideRook = this.turn ? 0 : 24;
		let kingSideRookI = this.turn ? 0 : 56;
		let queenSideRook = this.turn ? 7 : 31;
		let queenSideRookI = this.turn ? 7 : 63;
		let kingMoves = this.king(this.turn, index, this.board, true).map(v => v + index);
		let rookMovesKing = this.rook(this.turn, kingSideRookI,this.board).map(v => v + kingSideRookI);
		let rookMovesQueen = this.rook(this.turn,queenSideRookI, this.board).map(v => v + queenSideRookI);
		let intersectionKing = rookMovesKing.filter(element => kingMoves.includes(element));
		let intersectionQueen = rookMovesQueen.filter(element => kingMoves.includes(element));
		if (this.pieces[kingSideRook].userData.hasMoved === false && intersectionKing.length > 0 && this.pieces[kingSideRook].userData.taken === false) {
			path.push(-1);
		}
		if (this.pieces[queenSideRook].userData.hasMoved === false && intersectionQueen.length > 0 && this.pieces[queenSideRook].userData.taken === false) {
			path.push(1);
		}
		path = this.kingPinning(path,index,this.turn,this.board);
		if (path.includes(1)){
			moves.push(2);
		}
		if (path.includes(-1)){
			moves.push(-2);
		}
		moves = this.kingPinning(moves,index,this.turn,this.board);
		return moves;
	}

	// Calculates the positions that the piece on the given index can move to
	// and returns them as an array.
	// `turn` indicates whether it is white's turn or black's turn.
	// `modifier` indicates whether the checkable positions should be modified.
	checkablePositions(index, turn, modifier, board) {
		// `turnW` is a boolean that indicates whether it is white's turn or not.
		// It is used to index into various arrays.
		const turnW = turn ? 1 : 0;
		// Initialize local variables to store the possible moves for each piece.
		let localPawn;
		let localKnight;
		let localBishop;
		let localRook;
		let localQueen;
		// Calculate the possible moves for the piece on the given index.
		localPawn = this.checkPawn(turn, index, board);
		localKnight = this.knight(turn, index, board);
		localBishop = this.bishop(turn, index, board);
		localRook = this.rook(turn, index, board);
		localQueen = this.queen(turn, index, board);
		// Combine the possible moves for all pieces into one array.
		let checkPositions = localQueen.concat(localKnight.concat(localPawn));
		// Remove duplicate moves.
		for (let i = 0; i < checkPositions.length; ++i) {
			for (let j = i + 1; j < checkPositions.length; ++j) {
				if (checkPositions[i] === checkPositions[j])
					checkPositions.splice(j--, 1);
			}
		}
		// If `modifier` is true, update the check positions for each type of piece.
		if (modifier) {
			this.checkPositionsPawn[turnW] = localPawn.map(v => v + index);
			this.checkPositionsBishop[turnW] = localBishop.map(v => v + index);
			this.checkPositionsKnight[turnW] = localKnight.map(v => v + index);
			this.checkPositionsRook[turnW] = localRook.map(v => v + index);
			this.checkPositionsQueen[turnW] = localQueen.map(v => v + index);
		}
		// Add the index to each move and return the resulting array.
		checkPositions = checkPositions.map(v => v + index);
		return checkPositions;
	}

	// Calculates the possible moves for a pawn and returns them as an array.
	checkPawn(turn, index, board) {
		let moves = [];

		// Calculate the column of the pawn.
		let col = index % 8;

		if (turn) { //white
			if (board[index + 7] === null && col !== 0) {
				moves.push(7);
			}
			if (board[index + 9] === null && col !== 7) {
				moves.push(9);
			}
		} else { //black
			if (col !== 7 && board[index - 7] === null) {
				moves.push(-7);
			}
			if (col !== 0 && board[index - 9] === null) {
				moves.push(-9);
			}
		}

		return moves;
	}

	checkPawnAlt(turn, index, board) {
		let moves = [];

		// Calculate the column of the pawn.
		let col = index % 8;
		if (turn) { //white
			if ((board[index - 7] === null || board[index - 7] === 3 ) && col !== 0) {
				moves.push(-7);
			}
			if ((board[index - 9] === null || board[index - 9] === 3 ) && col !== 7) {
				moves.push(-9);
			}
		} else { //black
			if (col !== 7 && (board[index + 9] === null || board[index + 9] === 27)) {
				moves.push(9);
			}
			if (col !== 0 && (board[index + 7] === null || board[index + 7] === 27)) {
				moves.push(7);
			}
		}

		return moves;
	}

	// Checks whether the current player is in check.
	checkCheck(turn) {
		//turnW is a boolean that indicates whether it is white's turn or not.
		//It is used to index into various arrays.
		//turnB is a boolean that indicates whether it is black's turn or not.
		//1 = white, 0 = black
		const turnB = !turn ? 1 : 0;//-opponent

		// Set the opposing player's check status to false.
		this.check[turnB] = false;

		let newMoves = [];

		// Determine the type and index of the selected piece.
		const pieceType = this.selectedPiece.type;
		const pieceIndex = this.selectedPiece.indexOfBoardPiece;
		// Calculate the possible future moves for the selected piece.
		switch (pieceType) {
		case "Rook":
			newMoves = this.rook(turn, pieceIndex, this.board).map(v => v + pieceIndex);
			break;
		case "Knight":
			newMoves = this.knight(turn, pieceIndex, this.board).map(v => v + pieceIndex);
			break;
		case "Bishop":
			newMoves = this.bishop(turn, pieceIndex, this.board).map(v => v + pieceIndex);
			break;
		case "Queen":
			newMoves = this.queen(turn, pieceIndex, this.board).map(v => v + pieceIndex);
			break;
		default:
			//bug identified in moveTwo, some cases moveTwo needs to be 1 where a piece can move two and put king in check
			// a temporary fix is to set moveTwo to 0
			//this however still does not correctly pin the king
			newMoves = this.pawn(this.turn, pieceIndex,0, this.board, false).map(v => v + pieceIndex);
			break;
		}

		// If the current player's piece is attacking the opposing player's king or
		// the opposing player's king is in any of the possible moves, set the opposing
		// player's check status to true.
		if (this[`checkPositions${pieceType}`] !== undefined) {
			if (newMoves.includes(this.board.indexOf(this.turn ? 27 : 3)) ||
				this[`checkPositions${pieceType}`][turnB].includes(pieceIndex)) {
				// Store the threatening positions in `threatPositions`.
				this.threatPositions = this.currentCheckPositions[turnB].filter(x => newMoves.includes(x));
				// If the opposing player is in checkmate, return 1. Otherwise, set the
				// opposing player's check status to true, identify the threatening piece,
				// and return 0.
				this.check[turnB] = true;
				this.threatIndex[turnB] = this.selectedPiece.pieceId; //set opponents threatening piece index
				let checkmate = this.checkmate(turn);
				if (checkmate) {
					return 1;
				} else {
					console.log("check");
					this.cells[this.board.indexOf(turn ? 27 : 3)].material.opacity = 1;
					this.cells[this.board.indexOf(turn ? 27 : 3)].material.color = {r: 1, g: 0, b: 0};
					return (checkmate ? 1 : 0);
				}
			}
			else if (this.stalemate(!turn)) {
				return 2;
			}
		}
	}

	stalemate(turn){
		const pieces = turn ? this.whitePieces : this.blackPieces;
		let newPositions = [];
		pieces.forEach((piece) => {
			let newMoves;
			switch (piece.userData.name) {
			case "Rook":
				newMoves = this.rook(turn, piece.userData.indexOfBoardPiece, this.board);
				newMoves = this.piecePinning(newMoves, piece.userData.indexOfBoardPiece, turn, this.board);
				if (newMoves !== undefined)
					newPositions = newPositions.concat(newMoves);
				break;
			case "Knight":
				newMoves = this.knight(turn, piece.userData.indexOfBoardPiece, this.board);
				newMoves = this.piecePinning(newMoves, piece.userData.indexOfBoardPiece, turn, this.board);
				if (newMoves !== undefined)
					newPositions = newPositions.concat(newMoves);
				break;
			case "Bishop":
				newMoves = this.bishop(turn, piece.userData.indexOfBoardPiece, this.board);
				newMoves = this.piecePinning(newMoves, piece.userData.indexOfBoardPiece, turn, this.board);
				if (newMoves !== undefined)
					newPositions = newPositions.concat(newMoves);
				break;
			case "Queen":
				newMoves = this.queen(turn, piece.userData.indexOfBoardPiece, this.board);
				newMoves = this.piecePinning(newMoves, piece.userData.indexOfBoardPiece, turn, this.board);
				if (newMoves !== undefined)
					newPositions = newPositions.concat(newMoves);
				break;
			case "Pawn":
				newMoves = this.pawn(turn, piece.userData.indexOfBoardPiece, piece.moveTwo, this.board, false);
				newMoves = this.piecePinning(newMoves, piece.userData.indexOfBoardPiece, turn, this.board);
				if (newMoves !== undefined)
					newPositions = newPositions.concat(newMoves);
				break;
			case "King":
				newMoves = this.king(turn, piece.userData.indexOfBoardPiece, this.board, true);
				if (newMoves !== undefined)
					newPositions = newPositions.concat(newMoves);
				break;
			}
		});
		return newPositions.length === 0;

	}

	checkmate(turn) { //!turn is the player in check (opposite of the player who just moved)
		let oppTurn = !turn; //opposingPlayer
		let turnB = this.turn ? 0 : 1;
		this.findSaviour(oppTurn);
		let moves = this.king(oppTurn, this.getKingIndex(oppTurn), this.board, true);
		if (moves.length === 0 && this.saviourPieces[turnB].length === 0 && this.threatIndex[turnB] > -1) {
			console.log("checkmate");
			return true;
		}
		return false;
	}

	initKing() { //finds the king and sets the currentCheckPositions
		this.currentCheckPositions[1] = this.checkablePositions(3, true, 1, this.board);
		this.currentCheckPositions[0] = this.checkablePositions(59, false, 1, this.board);
	}

	getKingIndex(turn) { //returns the index of the king
		return (turn ? this.board.indexOf(3) : this.board.indexOf(27));
	}

	//calculates a hypothetical game where it can analyse the possible future moves of all pieces
	//used to find which piece can stop a king being in check
	findSaviour(turn) {
		let turnW = turn ? 1 : 0;
		const pieces = turn ? this.whitePieces : this.blackPieces;
		const pieceSet = [];
		let threatPath = [];
		let path = [];
		let threatPiece = this.pieces[this.threatIndex[turnW]];
		switch (threatPiece.userData.name) {
		case "Rook":
			path = this.rook(turn, threatPiece.userData.indexOfBoardPiece, this.board).map(v => v + threatPiece.userData.indexOfBoardPiece);
			threatPath = path.filter(value => this.checkPositionsRook[turnW].includes(value));
			break;
		case "Knight":
			path = this.knight(turn, threatPiece.userData.indexOfBoardPiece, this.board).map(v => v + threatPiece.userData.indexOfBoardPiece);
			threatPath = path.filter(value => this.checkPositionsKnight[turnW].includes(value));
			break;
		case "Bishop":
			path = this.bishop(turn, threatPiece.userData.indexOfBoardPiece, this.board).map(v => v + threatPiece.userData.indexOfBoardPiece);
			threatPath = path.filter(value => this.checkPositionsBishop[turnW].includes(value));
			break;
		case "Queen":
			path = this.queen(turn, threatPiece.userData.indexOfBoardPiece, this.board).map(v => v + threatPiece.userData.indexOfBoardPiece);
			threatPath = path.filter(value => this.checkPositionsQueen[turnW].includes(value));
			break;
		default:
			path = this.checkPawn(turn, threatPiece.userData.indexOfBoardPiece, this.board).map(v => v + threatPiece.userData.indexOfBoardPiece);
			threatPath = path.filter(value => this.checkPositionsPawn[turnW].includes(value));
		}
		pieces.forEach((piece) => {
			if (piece.userData.taken !== true) {
				let newPositions;
				switch (piece.userData.name) {
				case "Rook":
					newPositions = this.rook(turn, piece.userData.indexOfBoardPiece, this.board).map(v => v + piece.userData.indexOfBoardPiece);
					break;
				case "Knight":
					newPositions = this.knight(turn, piece.userData.indexOfBoardPiece, this.board).map(v => v + piece.userData.indexOfBoardPiece);
					break;
				case "Bishop":
					newPositions = this.bishop(turn, piece.userData.indexOfBoardPiece, this.board).map(v => v + piece.userData.indexOfBoardPiece);
					break;
				case "Queen":
					newPositions = this.queen(turn, piece.userData.indexOfBoardPiece, this.board).map(v => v + piece.userData.indexOfBoardPiece);
					break;
				default:
					newPositions = this.pawn(turn, piece.userData.indexOfBoardPiece, piece.userData.moveTwo, this.board, false).map(v => v + piece.userData.indexOfBoardPiece);
				}
				if (newPositions.some(element => threatPath.includes(element)) || newPositions.includes(threatPiece.userData.indexOfBoardPiece)) {
					pieceSet.push(piece);
				}
			}
		});
		this.saviourPieces[turnW] = pieceSet;
	}


	unitTest(id, move, promotedPiece) { //used to test the game logic and for online move injection
		this.promotedPiece = promotedPiece;
		this.testPieceData(id);
		this.makeMove(move);
	}

	testPieceData(index) { //sets the piece data for the unit test
		if (this.turn) {
			this.playerPieces = this.whitePieces;
		} else {
			this.playerPieces = this.blackPieces;
		}
		this.oldColor = this.pieces[index].children[0].material.color;
		this.selectedPiece.pieceId = this.pieces[index].userData.pieceId;
		this.selectedPiece.indexOfBoardPiece = this.pieces[index].userData.indexOfBoardPiece;
		this.selectedPiece.row = Math.floor(this.selectedPiece.indexOfBoardPiece / 8);
		this.selectedPiece.col = Math.floor(this.selectedPiece.indexOfBoardPiece % 8);
		this.selectedPiece.type = this.pieces[index].children[0].name;
		this.selectedPiece.moveTwo = this.pieces[index].userData.moveTwo;
		this.calculateMoves();
	}

}

export {
	game
};